export const developersMode: boolean = false;
export const feRandomSort: boolean = true;
export const devCountry: string | undefined = "";
export const developersActions: boolean = false;

// If devCountry is set, then dbLimitStart is starting point for the stamp list
// export const dbLimitStart: string = "vn997_659";
// export const dbLimitStart: string = "et297";
// export const dbLimitStart: string = "730";
// export const dbLimitStart: string = "in5001385";
// export const dbLimitStart: string = "sl24714";
export const dbLimitStart: string = ""; //cz0938

export const developersModeSimple: boolean = false;
export const localJson: boolean = false;
export const loginEnabled: boolean = false;
export const hash: string = "";
export const imageDimensions: boolean = false;
export const withActions: boolean = false;
export const showLogo: boolean = false;

export const dbLimit: number = 60;
export const dbLimitHomepage: number = 20;

// how many stamps to show in the playground when theme is selected
// MAX 30 lebo firebase limituje na 30
export const dbLimitThemes: number = 30;

export const withCollectionWords: boolean = true;
export const withCollectionsBar: boolean = true;

export const localJsonStampLimitStart: number = 1;
export const localJsonStampLimitEnd: number = localJsonStampLimitStart + 250;
export const TAG_COUNT_MIN: number = 2;

// design layout properties
export const layoutUsageWidth: number = 0.86;
export const stampWidth: number = 250;
export const collectionStampWidth: number = 250;
export const masonrySpacing: number = 3;

export const themeText: string = "theme";
export const themesBlockIcon: boolean = false;
export const seoTitleSuffix = " | We Are Stamps . Net";
export const defaultPageTitle = "New look into thematic stamp collecting";
