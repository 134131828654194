import {
  Autocomplete,
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Skeleton,
} from "@mui/material";
import { WithLogic, withLogicContext } from "../../App";
import { stampWidth, withActions } from "../../configs";
import StampCardActions from "../StampCardActions";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { AssignmentReturned, RotateLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isDevelopersMode } from "../../utils/string-utils";
import { Stamp } from "../../types/stamps";

export const ENV_FILESERVER = "/";

interface Props extends WithLogic {
  stamp: Stamp;
  isLandingPage: boolean;
  index?: number;
  onImageClick?: (id: string) => void;
  onAddToAlbumButtonClick?: () => void;
  onAddToFavouritesButtonClick?: () => void;
  onRemoveFromAlbumButtonClick?: () => void;
  onDetailButtonClick?: (id?: string) => void;
  onLoadTagsClick?: (id?: string) => void;
  onDevAddThemeTag?: (tag: string, _id?: string) => void;
  onDevAddColor?: (color: string, _id?: string) => void;
  onDevAddCollectionId?: (collectionId: string, _id: string) => void;
  onDevAddLabel?: (label: string, _id: string) => void;
  onChangeWidthHeight?: (_id: string, width?: number, height?: number) => void;
  onNameChange?: (name: string, _id?: string) => void;
}

function StampSneak(props: Props) {
  const { stamp } = props;
  const [tag, setTag] = useState("");
  const [color, setColor] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [label, setLabel] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [sName, setSName] = useState(stamp.nameEn ?? stamp.name);
  const navigate = useNavigate();
  const loading = props.logic.playground().loading;

  const w =
    stamp.imgWidth ??
    (stamp.width && stamp.width !== 0 && stamp.width !== 1 ? stamp.width : 50);
  const h =
    stamp.imgHeight ??
    (stamp.height && stamp.height !== 0 && stamp.height !== 1
      ? stamp.height
      : 50);

  const onImageClick = () => {
    navigate(`/stamp/${stamp.timestampField}`, {
      replace: true,
    });

    console.log("image click ");
    if (props.onImageClick) {
      props.onImageClick(stamp.id);
    }
  };

  const imgSrc = stamp.imgLocal
    ? ENV_FILESERVER + stamp.imgLocal.replace("small", "medium")
    : ENV_FILESERVER +
        "/stamps/" +
        stamp.country +
        "/" +
        stamp.img?.split("/")?.[stamp.img?.split("/").length - 1] ?? stamp.img;

  return (
    <Box
      key={stamp._id}
      className={`stamp-sneak ht${Math.floor(((stampWidth / w) * h) / 40)}`}
      sx={{ width: `${stampWidth}px` }}
    >
      <Card
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          className={`stamp-sneak-img stamp-sneak-img-${stamp.country}`}
          sx={{
            height: (stampWidth / w) * h,
          }}
        >
          {loading === false ? (
            <a
              href={`/stamp/${stamp.timestampField}`}
              onClick={(e) => {
                onImageClick();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <img
                src={
                  "/stamps/" +
                  stamp.country +
                  "/resized/" +
                  imgSrc.split("/")?.[imgSrc.split("/").length - 1]
                }
                alt={stamp.name}
                width={stampWidth}
                height={(stampWidth / w) * h}
                loading="lazy"
                style={{
                  display: "block",
                  width: "100%",
                  margin: "0 auto",
                }}
              />
            </a>
          ) : (
            <Box className="stamp-sneak-img-skelet" />
          )}
        </Box>
        {loading === false ? (
          <Box className="stamp-sneak-name">{stamp.nameEn ?? stamp.name}</Box>
        ) : (
          <Skeleton
            className="stamp-sneak-name-skeleton"
            variant="text"
            width={
              props.index
                ? ((props.index % 7) + (props.index % 3)) * 20 + 50
                : Math.random() * 130 + 90
            }
            height={32}
          />
        )}
        {isDevelopersMode() && <Box> {stamp._id}</Box>}

        {withActions && (
          <StampCardActions
            stamp={stamp}
            onAddToAlbumButtonClick={props.onAddToAlbumButtonClick}
            onRemoveFromAlbumButtonClick={props.onRemoveFromAlbumButtonClick}
            onAddToFavouritesButtonClick={props.onAddToFavouritesButtonClick}
            onDetailButtonClick={props.onDetailButtonClick}
            onChangeWidthHeight={props.onChangeWidthHeight}
          />
        )}

        {isDevelopersMode() && (
          <>
            {stamp.collectionTags && (
              <p style={{ padding: "0 4px" }}>
                {stamp.collectionTags?.join(", ")}
              </p>
            )}

            {/* SET STAMP Tags */}
            {props.onDevAddThemeTag && (
              <Grid container alignItems="center" sx={{ margin: "10px" }}>
                <Grid item xs={9}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.logic.playground().tags.map((tag, i) => ({
                      label: tag.tag_id,
                      id: tag.tag_id,
                      key: i,
                    }))}
                    onChange={(_, v) => setTag(v?.id ?? "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Theme-tag"
                        onChange={(v) => {
                          setTag(v.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) => {
                      if (tag !== "") {
                        props.onDevAddThemeTag?.(tag, stamp._id);
                        setTag("");
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {/* SET STAMP._bgColor */}
            {props.onDevAddColor && (
              <Grid container alignItems="center" sx={{ margin: "10px" }}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    label="Bg color of stamp"
                    value={color}
                    onChange={(v) => {
                      setColor(v.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) => {
                      if (color !== "") {
                        props.onDevAddColor?.(color, stamp._id);
                        setColor("");
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {/* SET STAMP._collectionId */}
            {props.onDevAddCollectionId && (
              <Grid container alignItems="center" sx={{ margin: "10px" }}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    label="collection_id"
                    value={collectionId}
                    onChange={(v) => {
                      setCollectionId(v.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) => {
                      if (collectionId !== "") {
                        props.onDevAddCollectionId?.(
                          collectionId,
                          stamp._id ?? ""
                        );
                        setCollectionId("");
                        e.stopPropagation();
                      }
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {props.onNameChange && (
              <Grid container alignItems="center" sx={{ margin: "10px" }}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    label="Name"
                    value={sName}
                    onChange={(v) => {
                      setSName(v.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) => {
                      if (sName !== "") {
                        props.onNameChange?.(sName ?? "", stamp._id);
                        setSName("");
                        e.stopPropagation();
                      }
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {/* SET stamp height */}
            {props.onChangeWidthHeight && (
              <>
                <Grid container alignItems="center" sx={{ margin: "10px" }}>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      label="Width"
                      value={width}
                      onChange={(v) => {
                        setWidth(Number(v.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton
                      onClick={(e) => {
                        if (stamp._id && width > 0) {
                          props.onChangeWidthHeight?.(
                            stamp._id,
                            width,
                            stamp.height
                          );
                          setWidth(0);
                          e.stopPropagation();
                        }
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{ margin: "10px" }}>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      label="Height"
                      value={height}
                      onChange={(v) => {
                        setHeight(Number(v.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton
                      onClick={(e) => {
                        if (stamp._id && height > 0) {
                          props.onChangeWidthHeight?.(
                            stamp._id,
                            stamp.width,
                            height
                          );
                          setHeight(0);
                          e.stopPropagation();
                        }
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )}

            {props.onDevAddLabel && (
              <Grid container alignItems="center" sx={{ margin: "10px" }}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    label="label"
                    value={label}
                    onChange={(v) => {
                      setLabel(v.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) => {
                      if (stamp._id && label !== "") {
                        props.onDevAddLabel?.(label, stamp._id);
                        setLabel("");
                        e.stopPropagation();
                      }
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            <Grid container alignItems="center" sx={{ margin: "10px" }}>
              {props.onChangeWidthHeight && (
                <IconButton
                  onClick={(e) => {
                    if (stamp._id) {
                      props.onChangeWidthHeight?.(stamp._id);
                    }
                    e.stopPropagation();
                  }}
                >
                  <RotateLeft />
                </IconButton>
              )}
              {props.onLoadTagsClick && (
                <IconButton
                  onClick={(e) => {
                    if (stamp._id) {
                      props.onLoadTagsClick?.(stamp._id);
                    }
                    e.stopPropagation();
                  }}
                >
                  <AssignmentReturned />
                </IconButton>
              )}
            </Grid>
          </>
        )}
      </Card>
    </Box>
  );
}

export default withLogicContext(StampSneak);
